"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { IPromo } from "@/types/promo";

export default function Promos({ promos }: { promos: IPromo[] }) {
    if (!promos) return;
    return (
        <div className="section-element section-element-usp-bar md:py-2 bg-secondary">
            <div className="container max-w-5xl">
                <div className="swiper swiper-no-swiping">
                    <Swiper
                        className="swiper-wrapper flex"
                        spaceBetween={16}
                        loop={true}
                        slidesPerView={1}
                        autoplay={{ delay: 2000 }}
                        breakpoints={{
                            448: { slidesPerView: 2, loop: promos.length > 2 },
                            768: { slidesPerView: 3, loop: promos.length > 3 },
                        }}
                    >
                        {promos.map((promo, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="swiper-slide"
                                >
                                    <div className="flex justify-center gap-x-2 pt-2 pb-1.5">
                                        <i className="icon-check-fill text-success"></i>
                                        <span className="text-h6-alt uppercase leading-none text-white/50 pt-0.5 tracking-wide">
                                            {promo.title}
                                        </span>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                        ...
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
