"use client";

import { useIsTouch } from "@/hooks/useIsTouch";
import useScrollDirection from "@/hooks/useScrollDirection";
import React, { PropsWithChildren } from "react";

const ScrollWrapper = ({ children }: PropsWithChildren) => {
    useScrollDirection();
    useIsTouch();

    return <div>{children}</div>;
};

export default ScrollWrapper;
