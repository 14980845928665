"use client";

import { useEffect, useState, useRef } from "react";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import Image from "next/image";
import { useDebounce } from "use-debounce";

import { useOnClickOutside } from "usehooks-ts";
import { searchProducts } from "@/server/actions/products";
import { IProduct } from "@/types/product";
import Link from "next/link";

export const formContainerClasses = cva("form-container relative", {
    variants: {
        position: {
            hero: "",
            topbar: "",
            header_main:
                "transition-all duration-500 [&.open]:absolute [&.open]:top-0 [&.open]:left-0 [&.open]:w-[calc(100%-50px)]",
        },
    },
});

export const inputContainerClasses = cva(
    "input-container flex bg-white border border-gray-100 rounded",
    {
        variants: {
            position: {
                hero: "p-2.5 lg:p-3.5",
                topbar: "p-1.5 lg:p-2.5",
                header_main:
                    "bg-primary rounded p-2 lg:p-2.5 [&.open]:w-full focus-within:bg-gray-700'",
            },
        },
        defaultVariants: {
            position: "hero",
        },
    }
);

export const inputClasses = cva(
    "flex-1 w-full py-0 border-0 focus:ring-0 placeholder:text-gray-400 text-gray-800",
    {
        variants: {
            position: {
                hero: "lg:text-lg",
                topbar: "",
                header_main:
                    "flex-1 w-full py-0 border-0 focus:ring-0 placeholder:text-gray-400 text-white bg-transparent",
            },
        },
        defaultVariants: {
            position: "hero",
        },
    }
);

export const btnClasses = cva("button button-secondary", {
    variants: {
        position: {
            hero: "button-md",
            topbar: "button-sm",
            header_main: "button-sm",
        },
    },
    defaultVariants: {
        position: "hero",
    },
});

type Position = "hero" | "topbar" | "header_main";

const SearchForm = ({
    position,
    onOpen,
}: {
    position: Position;
    onOpen?: (value: boolean) => void;
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");
    const [query] = useDebounce(value, 300);
    const inputPlaceholder = "Search";
    const [products, setProducts] = useState<IProduct[]>([]);

    const handleSearch = async (query: string) => {
        if (query) {
            const response = await searchProducts(query);
            if (response) {
                setProducts(response.data);
            }
        }
    };
    const handleClickOutside = () => {
        setProducts([]);
        setValue("");
    };

    useOnClickOutside(ref, handleClickOutside);

    useEffect(() => {
        handleSearch(query);
    }, [query]);

    return (
        <div className="section-element section-element-search-form">
            <div id="top-bar-search-container" ref={ref}>
                <div className={cn(formContainerClasses(), { open })}>
                    <div
                        className={cn(inputContainerClasses({ position }), {
                            "rounded-b-none !border-black/50": open,
                            shadow: !open,
                        })}
                    >
                        <input
                            onBlur={() => {
                                setOpen(false);
                                if (onOpen) {
                                    onOpen(false);
                                }
                            }}
                            onFocus={(e) => {
                                setOpen(true);
                                if (onOpen) {
                                    onOpen(true);
                                }
                            }}
                            type="search"
                            name="query"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            className={inputClasses({ position })}
                            autoComplete="off"
                            placeholder={inputPlaceholder}
                        />

                        <button
                            aria-label={"Search"}
                            type="submit"
                            className={btnClasses({ position })}
                        >
                            <i className="icon-magnifer-outline"></i>
                            {position !== "header_main" ? "Search" : null}
                        </button>
                    </div>
                    {products.length > 0 && (
                        <div className="absolute z-10 top-full left-0 w-full border border-gray-100 bg-white shadow-xl rounded-b">
                            <div className="top-results px-2 py-4 space-y-1 text-gray-500 max-h-[200px] overflow-y-scroll">
                                {products.map((product: IProduct) => {
                                    return (
                                        <Link
                                            href={`/product/${product.slug}/${product.documentId}`}
                                            key={product.id}
                                            className="result flex gap-x-2 items-center px-2 hover:bg-gray-100 transition-colors"
                                        >
                                            <span className="inline-block shrink-0 w-16 aspect-4/3 bg-cover">
                                                <Image
                                                    width="600"
                                                    height="402"
                                                    src={
                                                        product.featured_image
                                                            .url
                                                    }
                                                    className="w-full h-full responsive-image responsive-image--contain"
                                                    alt={product.name}
                                                    sizes="(max-width: 560px) 300px, 568px"
                                                    loading="lazy"
                                                    decoding="async"
                                                />
                                            </span>
                                            <span className="truncate">
                                                {product.name}
                                            </span>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchForm;
