import { useCallback, useEffect, useState } from "react";

const useScrollDirection = (scrollOffset = 50) => {
    const [scrollDirection, setScrollDirection] = useState("");
    const [scrollPos, setScrollPos] = useState(0);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const handleScroll = useCallback(() => {
        const currentScrollPos = document.body.getBoundingClientRect().top;
        const diff = Math.abs(currentScrollPos - prevScrollPos);

        if (diff < 10) return;

        if (currentScrollPos > prevScrollPos) {
            setScrollDirection("up");
        } else {
            setScrollDirection("down");
        }

        if (Math.abs(currentScrollPos) > scrollOffset) {
            if (scrollDirection === "up") {
                document.documentElement.classList.add("scrolled-up");
                document.documentElement.classList.remove("scrolled-down");
            } else if (scrollDirection === "down") {
                document.documentElement.classList.add("scrolled-down");
                document.documentElement.classList.remove("scrolled-up");
            }

            document.documentElement.classList.add("scrolled");
        } else {
            document.documentElement.classList.remove(
                "scrolled",
                "scrolled-up",
                "scrolled-down"
            );
        }

        setPrevScrollPos(currentScrollPos);
    }, [prevScrollPos, scrollDirection, scrollOffset]);

    useEffect(() => {
        handleScroll();
        document.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return { scrollDirection, scrollPos };
};

export default useScrollDirection;
