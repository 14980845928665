import { useEffect } from "react";

export const useIsTouch = () => {
    const checkForTouchDevice = () => {
        const isTouchDevice =
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            //@ts-ignore
            navigator.msMaxTouchPoints > 0;

        if (isTouchDevice) {
            document.documentElement.classList.add("touchevents");
            document.documentElement.classList.remove("no-touchevents");
        } else {
            document.documentElement.classList.add("no-touchevents");
            document.documentElement.classList.remove("touchevents");
        }
    };

    useEffect(() => {
        checkForTouchDevice();
    }, []);
};
